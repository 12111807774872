import QueryUtils from "../queryUtils";

const queries = [
  // Read
  `query GetProclamateurs( $congregation: String!, $cursor: String ) {
    proclamateurs( congregation_uuid: $congregation, envelopes_response: 0, after: $cursor ) {
      edges {
        cursor

        node {
          nom
          prenom

          envelopes( first: 100, send: true, response: 0 ) {
            edges {
              node {
                hour
                ics
                uuid

                invitation {
                  dateDay
                  hour
                  minute
                  shift
                  
                  lieu {
                    nom
                  }
                }
              }
            }
          }
        }
      }

      pageInfo {
        hasNextPage
      }
    }
  }`,
  // Update
  `mutation UpdateInvitation( $entity: updateEnvelopeInput! ) {
    updateEnvelope( input: $entity ) {
      envelope {
        uuid
      }
    }
  }`,
  // Delete
  ``,
];

export default new QueryUtils(queries);
