<template>
  <div class="vue">
    <div class="container-vue container">
      <h1>Liste des invitations sans réponse</h1>
      <transition-group name="list-complete">
        <div
          class="list-complete-item"
          v-for="proclamateur in proclamateurs"
          :key="proclamateur.username"
        >
          <div class="title proclamateur">
            <h2>{{ proclamateur.username }}</h2>
          </div>
          <div
            v-for="(envelope, index) in proclamateur.envelopes"
            :key="envelope.node.uuid"
          >
            <div
              class="title"
              v-bind:place="envelope.node.invitation.lieu.nom"
              v-if="
                index === 0 ||
                (index > 0 &&
                  isShowPlace(
                    envelope.node.invitation.lieu.nom,
                    proclamateur.envelopes[index - 1].node.invitation.lieu.nom
                  ))
              "
            >
              <h2>{{ envelope.node.invitation.lieu.nom }}</h2>
            </div>
            <item
              v-bind:proclamateur="proclamateur.username"
              v-bind:item="envelope"
              v-on:onSave="onSaveEnvelope"
            />
          </div>
        </div>
      </transition-group>
      <infinite-loading
        @infinite="getProclamateurs"
        spinner="spiral"
        ref="infiniteLoading"
      >
        <template v-slot:no-more>
          <span></span>
        </template>
        <template v-slot:no-results>
          <div class="message no-result">
            <h2>Pas d'invitation sans réponse.</h2>
          </div>
        </template>
        <template v-slot:error>
          <div class="message">
            <h2>Opps, something went wrong.</h2>
          </div>
        </template>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import Item from "@/components/ProclamateurItem";
import Graphql from "../utils/graphQl";
import QueryUtils from "../utils/queries/proclamateurQuery";
import InvitationMixin from "../utils/invitationMixin";
import QueryMixin from "../utils/queryMixin";
import RedirectMixin from "../utils/redirectionMixin";

export default {
  name: "Proclamateur",

  mixins: [InvitationMixin, QueryMixin, RedirectMixin],

  title() {
    return `${this.title} - ${this.congregationName}`;
  },

  data() {
    return {
      proclamateurs: [],
      cursorProclamateur: "",
      title: this.$store.state.title,
      token: this.$store.state.token,
      congregationName: this.$store.state.congregationName,
      congregationUuid: this.$store.state.congregationUuid,
    };
  },

  components: {
    Item,
  },

  watch: {
    proclamateurs: function (value) {
      if (value.length === 0) {
        this.$refs.infiniteLoading.stateChanger.reset();
        this.$refs.infiniteLoading.stateChanger.complete();
      }
    },
  },

  methods: {
    getProclamateurs: function ($state) {
      QueryUtils.setRead();
      QueryUtils.setVariables(
        this.getQueryVariables(
          "congregation",
          "",
          this.congregationUuid,
          this.cursorProclamateur
        )
      );

      // Start GraphQL - Return Envelope
      new Graphql(this.token, QueryUtils).request().then((response) => {
        let arrayResult = [];
        let arrayProclamateurs = response.data.data.proclamateurs.edges;

        for (let index = 0; index < arrayProclamateurs.length; index++) {
          let value = arrayProclamateurs[index].node;
          let result = value.envelopes.edges;

          this.cursorProclamateur = arrayProclamateurs[index].cursor;

          // Trie
          result = result.sort(
            (a, b) =>
              a.node.invitation.lieu.nom.localeCompare(
                b.node.invitation.lieu.nom
              ) ||
              new Date(a.node.invitation.dateDay) -
                new Date(b.node.invitation.dateDay)
          );

          if (result.length !== 0) {
            let proclamateur = {
              username: value.nom + " " + value.prenom,
              envelopes: result,
            };

            arrayResult.push(proclamateur);
          }
        }

        if (arrayResult.length > 0) {
          this.proclamateurs.push(...arrayResult);

          $state.loaded();
        } else {
          if (!response.data.data.proclamateurs.pageInfo.hasNextPage) {
            if (this.proclamateurs.length === 0) {
              $state.reset();
            }

            $state.complete();
          } else {
            $state.loaded();
          }
        }
      });
      // End GraphQL
    },

    onSaveEnvelope: function (proclamateurItem, uuid, answer) {
      QueryUtils.setUpdate();
      QueryUtils.setVariables(this.setMutableVariables(uuid, answer));

      // Start GraphQL - Mutation Envelope
      new Graphql(this.token, QueryUtils).request().then((response) => {
        if (
          typeof response.data.data.updateEnvelope.envelope.uuid !== "undefined"
        ) {
          let arrayResult = [];
          proclamateurItem.clear();

          for (let x = 0; x < this.proclamateurs.length; x++) {
            let username = this.proclamateurs[x].username;
            let result = this.proclamateurs[x].envelopes;

            var indice = result
              .map(function (element) {
                return element.node.uuid;
              })
              .indexOf(uuid);

            if (indice !== -1) {
              result.splice(indice, 1);
            }

            if (result.length) {
              let proclamateur = {
                username: username,
                envelopes: result,
              };

              arrayResult.push(proclamateur);
            }
          }

          this.proclamateurs = arrayResult;
        }
      });
      // End GraphQL
    },
  },
};
</script>
