<template>
  <div class="envelope">
    <div class="header-envelope">
      <div class="history-envelope">
        <h3>[ {{ proclamateur }} ]</h3>
      </div>
      <div class="ticket-envelope">
        <h3>{{ envelope.place }}</h3>
        <div class="date-envelope">
          {{ getDate() }}
        </div>
        <div>
          {{ getCreneauTitle() }}
        </div>
      </div>
      <div class="row justify-content-center">
        <button
          class="btn btn-success btn-md center-block button-vue"
          v-on:click="accept"
          :disabled="isClicked"
        >
          <icon
            class="wait"
            name="spinner"
            spin
            v-bind:class="[isSave ? '' : 'hide']"
          />
          Accepter
        </button>
        <button
          class="btn btn-danger btn-md center-block button-vue"
          v-on:click="refuse"
          :disabled="isClicked"
        >
          <icon
            class="wait"
            name="spinner"
            spin
            v-bind:class="[isDelete ? '' : 'hide']"
          />
          Refuser
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-awesome/icons";
import Icon from "vue-awesome/components/Icon";
import EnvelopeMixin from "../utils/envelopeMixin";

export default {
  name: "ProclamateurItem",

  mixins: [EnvelopeMixin],

  data() {
    return {
      envelope: {
        uuid: this.item.node.uuid,
        creneau: this.item.node.hour,
        place: this.item.node.invitation.lieu.nom,
        date: this.item.node.invitation.dateDay,
        hour: this.item.node.invitation.hour,
        minute: this.item.node.invitation.minute,
        shift: this.item.node.invitation.shift,
      },
      isClicked: false,
      isSave: false,
      isDelete: false,
    };
  },

  props: {
    proclamateur: String,
    item: Object,
  },

  components: {
    Icon,
  },
};
</script>
